<script setup>
import { Link } from '@inertiajs/vue3';
import { inject } from 'vue';

import Icon from '@/Components/Icon.vue';
import Right from '@i/expand_more-right.svg';

const route = inject('route');

const props = defineProps({
    item: Object,
    borderBottom: Boolean,
});

const redirect = (href) => {
    window.location.href = route(href);
};
</script>

<template>
    <div>
        <div
            :class="{ 'border-b': borderBottom }"
            class="relative flex justify-between w-full py-3 pl-8 overflow-hidden border-blue/30 border-t"
        >
            <div class="flex flex-col">
                <Link :href="route(item.route)" class="flex items-center gap-1 text-base font-bold text-blue">
                    {{ item.name }}
                </Link>
                <span class="text-base text-blue">
                    {{ item.description }}
                </span>
            </div>
            <div class="flex items-center justify-between">
                <div class="flex items-center gap-6">
                    <div @click="redirect(item.route)" class="w-5 cursor-pointer group">
                        <Icon :icon="Right" class="relative right-0 float-right w-3 h-12 transition-all" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
